<template>
  <div id="app">
    <Totop></Totop>
    <router-view />
  </div>
</template>

<script>
import Totop from "@/components/bottomSoll.vue";
import axios from "axios";
export default {
  name: "App",
  components: {
    Totop,
  },
  mounted() {
    //判断是手机端还是pc如果是pc直接跳转到pc端
    if (!this._isMobile()) {
      axios.get("/config.json").then((res) => {
        // console.log(res.data.pcUrl);

        window.location.href = res.data.pcUrl;
      });
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style>
@import url("@/assets/css/common.css");
</style>
<style lang="scss">
table td {
  height: 0.26rem;
}

td {
  padding: 0 0.05rem;
  min-width: 0.5rem;
}
.van-ellipsis {
  font-size: 28px !important;
}
.van-picker__confirm,
.van-picker__cancel {
  font-size: 36px !important;
}
.van-picker__toolbar {
  height: 92px !important;
}
.van-nav-bar__content {
  position: fixed;
  top: 0;
  width: 750px;
  height: 92px;
  background: #152c50;
  .van-nav-bar__title {
    line-height: 92px;
    font-size: 36px !important;
    font-weight: 600 !important;
    color: #ffffff;
    letter-spacing: 5.33px !important;
  }

  element.style {
  }
  .ql-editor * {
    white-space: pre-wrap;
  }
  .ql-snow,
  .ql-snow * {
    box-sizing: border-box;
  }

  .van-nav-bar__arrow {
    font-size: 33.33px;
    color: #ffffff;
    font-weight: 600;
  }
}
.van-toast__text {
  font-size: 34px;
  padding: 20px;
}
</style>