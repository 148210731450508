import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: () => import("@/views/indexs.vue"),
    name: "indexs",
    meta: {
      title: "竞猜资讯",
    },
    redirect: "index",
    children: [{
      path: "index",
      component: () => import("@/views/index.vue"),
      name: "index",
      meta: {
        title: "竞猜资讯",
      },
    }]
  },
  {
    path: "/information",
    component: () => import("@/views/information/index"),
    name: "Information",
    meta: {
      title: "北单资讯",
    },
    children: [
      {
        path: "eventExpress",
        component: () => import("@/views/information/eventExpress"),
        name: "EventExpress",
        meta: {
          title: "赛事情报速递",
        }
      },
      {
        path: "notice",
        component: () => import("@/views/information/notice"),
        name: "Notice",
        meta: {
          title: "通知公告",
        }
      },
      {
        path: "splitLine",
        component: () => import("@/views/information/splitLine"),
        name: "SplitLine",
        meta: {
          title: "热点新闻",
        }
      },
      {
        path: "afterTheGame",
        component: () => import("@/views/information/afterTheGame"),
        name: "AfterTheGame",
        meta: {
          title: "赛后资讯",
        }
      },
    ]
  },
  {
    path: "/injuryStop",
    component: () => import("@/views/injuryStop/index"),
    name: "InjuryStop",
    meta: {
      title: "伤停",
    },
    children: [
      {
        path: "focalInjuryStop",
        component: () => import("@/views/injuryStop/focalInjuryStop"),
        name: "FocalInjuryStop",
        meta: {
          title: "焦点伤停",
        }
      },
    ]
  },
  {
    path: "/encyclopedia",
    component: () => import("@/views/encyclopedia/index"),
    name: "Encyclopedia",
    meta: {
      title: "北单百科",
    },
    children: [
      {
        path: "beiDanEncyclopedia",
        component: () => import("@/views/encyclopedia/beiDanEncyclopedia"),
        name: "BeiDanEncyclopedia",
        meta: {
          title: "北单百科",
        }
      }
    ]
  },
  {
    path: "/sp",
    component: () => import("@/views/sp/index.vue"),
    name: "sp",
    meta: {
      title: "投注分布",
    },
    children: [
      {
        path: "spf",
        component: () => import("@/views/sp/spf.vue"),
        name: "Spf",
        meta: {
          title: "胜平负",
        }
      },
      {
        path: "xbcbf",
        component: () => import("@/views/sp/xbcbf.vue"),
        name: "xbcbf",
        meta: {
          title: "下半场比分",
        }
      },
      {
        path: "sf",
        component: () => import("@/views/sp/sf.vue"),
        name: "Sf",
        meta: {
          title: "胜负过关",
        }
      },
      {
        path: "bqcspf",
        component: () => import("@/views/sp/bqcspf.vue"),
        name: "Bqcjqs",
        meta: {
          title: "半全场胜平负",
        }
      },
      {
        path: "zjqs",
        component: () => import("@/views/sp/zjqs.vue"),
        name: "zjqs",
        meta: {
          title: "总进球数",
        }
      },
      {
        path: "dcbf",
        component: () => import("@/views/sp/dcbf.vue"),
        name: "dcbf",
        meta: {
          title: "单场比分",
        }
      },
      {
        path: "sxpds",
        component: () => import("@/views/sp/sxpds.vue"),
        name: "sxpds",
        meta: {
          title: "上下盘单双数",
        }
      }
    ]
  },
  {
    path: "/drawNotice",
    component: () => import("@/views/drawNotice/index"),
    name: "DrawNotice",
    meta: {
      title: "赛果开奖",
    },
    children: [
      {
        path: "winEvenLose",
        component: () => import("@/views/drawNotice/winEvenLose"),
        name: "WinEvenLose",
        meta: {
          title: "赛果开奖",
        }
      }
    ]
  },
  {
    path: "/eventInformation",
    component: () => import("@/views/eventInformation/index"),
    name: "EventInformation",
    meta: {
      title: "赛事资料",
    },
    children: [
      {
        path: "gameInformation",
        component: () => import("@/views/eventInformation/gameInformation"),
        name: "GameInformation",
        meta: {
          title: "赛事",
        }
      },
      {
        path: "statistics",
        component: () => import("@/views/eventInformation/statistics"),
        name: "Statistics",
        meta: {
          title: "统计",
        }
      }
    ]
  },
  {
    path: "/jisuan",
    component: () => import("@/views/jisuan/index.vue"),
    name: "jisuan",
    meta: {
      title: "奖金计算",
    },
    children: [
      {
        path: "spf",
        component: () => import("@/views/jisuan/spf.vue"),
        name: "jisuanSpf",
        meta: {
          title: "胜平负",
        }
      },
      {
        path: "jisuan/xbcbf",
        component: () => import("@/views/jisuan/xbcbf.vue"),
        name: "jisuanxbcbf",
        meta: {
          title: "下半场比分",
        }
      },
      {
        path: "sf",
        component: () => import("@/views/jisuan/sf.vue"),
        name: "jisuanSf",
        meta: {
          title: "胜负过关",
        }
      },
      {
        path: "bqcspf",
        component: () => import("@/views/jisuan/bqcspf.vue"),
        name: "jisuanBqcjqs",
        meta: {
          title: "半全场胜平负",
        }
      },
      {
        path: "zjqs",
        component: () => import("@/views/jisuan/zjqs.vue"),
        name: "jisuanzjqs",
        meta: {
          title: "总进球数",
        }
      },
      {
        path: "dcbf",
        component: () => import("@/views/jisuan/dcbf.vue"),
        name: "jisuandcbf",
        meta: {
          title: "单场比分",
        }
      },
      {
        path: "sxpds",
        component: () => import("@/views/jisuan/sxpds.vue"),
        name: "jisuansxpds",
        meta: {
          title: "上下盘单双数",
        }
      }
    ]
  },
  {
    path: "/liveScores",
    component: () => import("@/views/liveScores/index.vue"),
    name: "LiveScores",
    meta: {
      title: "比分直播",
    },
    children: [
      {
        path: "score",
        component: () => import("@/views/liveScores/score"),
        name: "Score",
        meta: {
          title: "比分直播",
        }
      },
    ]
  },
  {
    path: "/winView",
    component: () => import("@/views/winView/index"),
    name: "winView",
    meta: {
      title: "赢家视角",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/winView/winView"),
        name: "winViewIndex",
        meta: {
          title: "赢家视角",
        }
      }
    ]
  },
  {
    path: "/schedule",
    component: () => import("@/views/schedule/index"),
    name: "schedule",
    meta: {
      title: "竞猜赛程",
    },
    children: [
      {
        path: "index",
        component: () => import("@/views/schedule/list"),
        name: "scheduleIndex",
        meta: {
          title: "竞猜赛程",
        }
      }
    ]
  },
  {
    path: "/against",
    component: () => import("@/views/against/index.vue"),
    name: "Against",
    meta: {
      title: "对阵数据",
    },
    children: [
      {
        path: "againstData",
        component: () => import("@/views/against/againstData"),
        name: "AgainstData",
        // meta: {
        //   title: "对阵数据",
        // }
      },
    ]
  },
  {
    path: "/articleAetails",
    name: "ArticleAetails",
    component: () =>
      import("@/views/information/articleAetails"),
    hidden: true
  },
  {
    path: "/articleAetails2",
    name: "ArticleAetails2",
    component: () =>
      import("@/views/information/articleAetails2"),
    hidden: true
  },
  {
    path: "/details",
    name: "Details",
    component: () =>
      import("@/views/encyclopedia/details"),
    hidden: true
  },
  {

    path: "/subdiscChange",
    component: () => import("@/views/against/subdiscChange"),
    name: "SubdiscChange",
    // meta: {
    //   title: "欧盘",
    // }
  },
  {
    path: "results",
    component: () => import("@/views/eventInformation/results"),
    name: "Results",
    // meta: {
    //   title: "赛程赛果",
    // }
  },
  {
    path: "leagueTable",
    component: () => import("@/views/eventInformation/leagueTable"),
    name: "LeagueTable",
    // meta: {
    //   title: "积分榜",
    // }
  },
  {
    path: "/winViewDetails",
    component: () => import("@/views/winView/videoText.vue"),
    name: "winViewDetails",
    hidden: true,
    meta: {
      title: "",
    }
  },
]

const router = new VueRouter({
  routes,
  mode: "hash",
})
export default router